import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pawel/Projekty/wyspiewana-tradycja/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polityka dotycząca cookies`}</h1>
    <p>{`Dla Państwa wygody strona Wyśpiewana tradycja używa plików cookies po to, by dostosować serwis do potrzeb użytkowników, a także w celach statystycznych.`}</p>
    <p>{`Pliki cookies (tzw. ciasteczka) to niewielkie pliki tekstowe wysyłane przez odwiedzany serwis internetowy do urządzenia internauty (komputer, smartfon itp.). Nie zawierają one żadnych danych osobowych.`}</p>
    <p>{`Wyśpiewana tradycja stosuje dwa typy plików cookies:`}</p>
    <ol>
      <li parentName="ol">{`wydajnościowe (zbierające informacje na temat sposobu używania witryny przez odwiedzających, np. najczęściej odwiedzanych stron lub komunikatów o błędach itp.),`}</li>
      <li parentName="ol">{`funkcjonalne (zapisujące ustawienia użytkownika, np. język, wyrażone zgody itp.), takie jak:`}<ol parentName="li">
          <li parentName="ol">{`cookies google-analytics.com – służą do prowadzenia statystyk dla witryny gov.pl; dokładny sposób działania i politykę prywatności Google Analytics można znaleźć pod adresem: `}<a parentName="li" {...{
              "href": "http://www.google.com/analytics/learn/privacy.html"
            }}>{`http://www.google.com/analytics/learn/privacy.html`}</a>{`,`}</li>
          <li parentName="ol">{`cookies sesyjne – są to tymczasowe informacje przechowywane w pamięci przeglądarki do momentu zakończenia sesji, czyli jej zamknięcia.`}</li>
        </ol></li>
    </ol>
    <p>{`Serwisy obce, do których niekiedy odsyła Wyśpiewana tradycja, mogą używać także innych plików cookies niż wymienione powyżej, w tym w szczególności plików umożliwiających logowanie się oraz dostarczanie reklam odpowiadających upodobaniom i zachowaniom użytkownika. Tego rodzaju pliki cookies w serwisie youtube.com – zawierające preferencje użytkownika, oraz liczydło kliknięć; polityka prywatności serwisu YouTube opisana jest pod adresem: `}<a parentName="p" {...{
        "href": "http://www.google.pl/intl/pl/policies/privacy/"
      }}>{`http://www.google.pl/intl/pl/policies/privacy/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      